@import "/resources/scss/theme/base";

footer {
  &.footer {
    width: 100%;
    @media (max-width: 967px) {
      background: #f5f5f5;
    }

    .footer {
      &__logo {
        .light {
          display: block;
        }
        .dark {
          display: none;
        }
      }

      &__socialmedia {
        display: inline-block;
        width: 100%;
        align-items: center;
        @media only screen and (max-width: 967px) {
          display: none;
          align-items: flex-start;
        }

        div {
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: rem(1.08);
          font-size: rem(12);
        }

        a {
          margin-left: 23px;
          width: 20px;
          height: 20px;
          background-size: cover;
          display: inline-block;
          @media only screen and (max-width: 967px) {
            width: 30px;
            height: 30px;
            margin-left: 0;
            margin-top: 10px;
            margin-right: 1.6rem;
          }

          &.fb-icon {
            background-image: url("../resources/icons/fb-icon.svg");
            transition: background-image 0.2s ease-in-out;

            &:hover {
              background-image: url("../resources/icons/fb-icon-hover.svg");
              transition: background-image 0.2s ease-in-out;
            }
          }

          &.instagram-icon {
            background-image: url("../resources/icons/instagram-icon.svg");

            &:hover {
              background-image: url("../resources/icons/instagram-icon-hover.svg");
            }
          }

          &.youtube-icon {
            background-image: url("../resources/icons/youtube-icon.svg");

            &:hover {
              background-image: url("../resources/icons/youtube-icon-hover.svg");
            }
          }
        }

        a + a {
          margin-left: 16px;
        }
      }

      &__container {
        width: 100%;
        padding-block: rem(20);
        padding-top: 0px;
      }

      &__row1 {
        width: 100%;
        align-items: center;
        display: flex;
        padding-top: 30px;
        padding-bottom: 30px;
        border-top: 1px solid black;

        .footer {
          &__socialmedia {
            margin-left: auto;
            display: flex;
            width: initial;
            @media only screen and (max-width: 967px) {
              display: none;
            }
          }
          &__logo {
            align-items: flex-start;

            img {
              max-height: 33px;
            }
          }

          &__cheese {
            margin-left: auto;
            justify-self: end;
            @media only screen and (min-width: 968px) {
              margin-left: 68px;
            }
          }
        }

        &__logo {
          width: 100%;
        }

        &__location {
          padding: 1rem;
          width: 100%;
          background-color: rgb(150, 150, 150);
        }

        .footer__cheese {
          margin-top: 0px;
          & .country-dropdown {
            position: relative;
            display: inline-block;
          }

          & .dropdown-toggle {
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            cursor: pointer;
            background-color: #e8e8e8;
            width: 70px;
            height: 40px;
            padding: 10px;
          }

          & .arrow-icon {
            transition: transform 0.3s ease-in-out;
          }

          & .globe-icon {
            width: 24px;
            height: 24px;
            margin-right: 10px;
          }

          & .dropdown-list {
            position: absolute;
            top: 50px;
            right: 0;
            width: 150px;
            border: 1px solid #fff;
            border-top: none;
            display: none;
            border-radius: 4px;
            background: #fff;
            box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.08);
          }

          & .dropdown-item {
            padding: 10px;
            cursor: pointer;
            transition: background-color 0.3s ease-in-out;

            &:hover {
              background-color: #f0f0f0;
            }
          }
        }
      }

      &__row2 {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        @media only screen and (max-width: 967px) {
          flex-direction: column;
        }

        .footer {
          &__socialmedia {
            order: 2;
            display: none;
            @media only screen and (max-width: 967px) {
              display: inline-block;
            }
          }
          &__navigation {
            order: 3;
            display: flex;
            flex-direction: row;
            margin-top: 20px;

            @media only screen and (max-width: 967px) {
              margin-top: 20px;
              flex-direction: column;
              div {
                margin-top: 30px;
              }
            }

            h3 {
              font-family: $main-heading-font-family;
              font-size: rem(24);
              margin-bottom: rem(20);
              line-height: rem(24);
              font-weight: 400;
            }

            ul {
              list-style: none;

              li {
                a[target="_blank"] {
                  position: relative;
                  padding-right: 11px;

                  &::after {
                    content: "";
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    background-image: url("../resources/icons/external-link-icon.svg");
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                  }
                }
              }
            }

            .menu-item {
              font-size: 1.2rem;
              line-height: 3.4rem;
              @media only screen and (min-width: 1268px) {
                font-size: 1rem;
                line-height: 1.8rem;
              }
            }

            div + div {
              @media only screen and (min-width: 968px) {
                margin-left: 50px;
              }
            }

            &__left {
              ul {
                columns: 2;
                white-space: nowrap;
                @media only screen and (max-width: 1368px) {
                  columns: 2;
                }

                @media only screen and (max-width: 1268px) {
                  columns: 1;
                }

                @media only screen and (max-width: 967px) {
                  columns: 2;
                }

                li {
                  margin-right: 10px;
                }
              }
            }

            &__center {
              margin-top: 0px;
              width: 100%;

              ul {
                columns: 2;
                white-space: nowrap;
                @media only screen and (max-width: 1268px) {
                  columns: 1;
                }

                @media only screen and (max-width: 967px) {
                  columns: 2;
                }
              }
            }

            &__right {
              width: 100%;
              margin-top: 0px;
            }

            &__items {
              width: 100%;
            }
          }
        }

        .footer__cta {
          order: 1;
          @media only screen and (min-width: 968px) {
            order: 3;
          }
          & .footer__newsletter {
            border-radius: 6px;
            padding: 20px;
            background-color: white;

            @media only screen and (min-width: 968px) {
              width: 356px;
            }
            & h3 {
              font-family: $main-heading-font-family;
              font-size: 24px !important;
              line-height: 24px;
              font-weight: 400 !important;
            }

            & form {
              .frm_submit {
                //increasing specificity to remove unecessasry !importants
                & .frm_button_submit {
                  border-radius: 0.125rem !important;
                  background: $btn-bg-color !important;
                  color: $white !important;
                  min-width: 142px;
                  padding: 14px 29px !important;
                  font-family: "Avenir Next";
                  font-style: normal;
                  font-weight: 600 !important;
                  font-size: 0.9rem !important;
                  letter-spacing: 1.44px !important;
                  font-weight: 600 !important;
                  margin-top: 16px;
                  margin-bottom: 0px;

                  &:hover,
                  &:focus {
                    background: $btn-bg-hover !important;
                  }
                }
              }
              & .frm_form_field {
                margin-bottom: 0px !important;
              }

              & label {
                display: none !important;
              }

              & input,
              & select {
                padding: 0px;
                border: 0;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                border-radius: 0;
                border-bottom: solid 1px #444;
                box-shadow: none;
                font-size: inherit !important;
                color: #222221 !important;
              }

              & select {
                background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
                  no-repeat;
                background-position: calc(100% - 0.75rem) center !important;
                -moz-appearance: none !important;
                -webkit-appearance: none !important;
                appearance: none !important;
                padding-right: 2rem !important;
                color: #222221 !important;
              }

              & select option {
                padding: 5px !important;
              }

              & ::placeholder {
                color: #222221 !important;
                opacity: 1;
              }
            }
          }
        }
      }

      &__row3 {
        width: 100%;

        .footer {
          &__copyright {
            width: 100%;

            p {
              color: #626262;
              font-size: rem(11);
              letter-spacing: rem(0.22);
              font-weight: 500;
            }
          }
        }
      }
    }

    @media (max-width: 967px) {
      .footer__row2 {
        display: flex;
        flex-direction: column;
        margin-top: 0px;

        .footer__socialmedia {
          order: 2;
        }

        .footer__navigation {
          order: 3;
        }

        .footer__cta {
          order: 1;
          margin-top: 5px;
          margin-bottom: 30px;

          .footer__newsletter {
            background: #fff;
            padding: 20px 24px 14px;
            border-radius: 4px;
          }
        }
      }
    }
  }
}

// Dark mode CSS

.theme-dark {
  .footer {
    background-color: #1c1c1e !important;

    &__logo {
      .light {
        display: none !important;
      }
      .dark {
        display: block !important;
      }
    }

    &__socialmedia {
      // Using background img source, so can't target svg > fill
      a {
        filter: invert(1);

        &:hover {
          filter: invert(0);
        }
      }
    }

    &__row1 {
      .footer__cheese {
        & .globe-icon {
          svg {
            stroke: #000;
          }
        }

        .dropdown-item {
          color: #000;
        }
      }
    }
  }

  footer.footer .footer__row1 {
    border-top: 1px solid #fff;
  }

  footer.footer .footer__container {
    color: #fff !important;
  }

  footer.footer .footer__row2 .footer__cta .footer__newsletter {
    background-color: #000;
  }

  footer.footer
    .footer__row2
    .footer__cta
    .footer__newsletter
    form
    .frm_button_submit {
    background: #ad9b5c !important;
    color: #000 !important;
  }

  footer.footer .footer__row2 .footer__cta .footer__newsletter form {
    input,
    select {
      background-color: transparent !important;
      border-bottom: solid 2px #fff;
      color: #fff !important;

      ::placeholder {
        color: #a4a4a5 !important;
      }
    }
  }

  footer.footer
    .footer__row2
    .footer__cta
    .footer__newsletter
    #frm_field_10_container {
    // #frm_field_10_container = newsletter-sign-up-form-form.xml select
    position: relative;

    &:before {
      content: "";
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #fff;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
    }
  }
}
