/**
 * DISPLAY: GRID shorthand classes and mixins
 *
 * This SASS stylesheet defines utility classes for creating grid layouts using a BEM (Block, Element, Modifier) naming convention.
 * It provides a flexible grid system that can adapt to different screen sizes and allows you to create responsive layouts easily.
 */
@import "./base";

/** CSS Grid */
[class*="__row"] {
  @include grid();

  + [class*="__row"] {
    @media only screen and (max-width: $mobile-landscape) {
      margin-top: rem(10);
    }
  }

  &.grid-no-gap {
    grid-column-gap: 0;

    &-tablet {
      @media only screen and (min-width: $phablet) and (max-width: $tablet) {
        column-gap: rem(0);
      }
    }

    &-mobile {
      @media only screen and (max-width: $mobile-landscape) {
        column-gap: rem(0);
      }
    }
  }

  &.grid-reversed {
    direction: rtl;

    > *,
    [class*="__column"] {
      direction: ltr;
    }
  }

  > *,
  [class*="__column"] {
    display: flex;
    flex-direction: column;
    margin: 0;

    [class*="__row"] {
      @include grid();
    }

    @media only screen and (max-width: $mobile-landscape) {
      + *,
      [class*="__column"] {
        margin-top: rem(30);
      }
    }
  }

  @for $i from 1 through 12 {
    &.is-equalized {
      .grid-span-#{$i} {
        grid-column: auto/span 6;
      }
    }

    .col-#{$i},
    .grid-span-#{$i} {
      grid-column: auto/span $i;
    }

    .grid-start-#{$i} {
      grid-column-start: $i;
    }

    .grid-row-#{$i} {
      grid-row: $i;
    }

    .order-#{$i} {
      order: $i;
    }

    @media only screen and (min-width: $base + 1) {
      .grid-span-#{$i}-desktop {
        grid-column: auto/span $i;
      }

      .grid-start-#{$i}-desktop {
        grid-column-start: $i;
      }
    }

    @media only screen and (min-width: $base + 1) and (max-width: $desktop) {
      .grid-span-#{$i}-base {
        grid-column: auto/span $i;
      }
      .grid-start-#{$i}-base {
        grid-column-start: $i;
      }
    }

    @media only screen and (min-width: $phablet + 1) and (max-width: $tablet) {
      .grid-span-#{$i}-tablet {
        grid-column: auto/span $i;
      }
      .grid-start-#{$i}-tablet {
        grid-column-start: $i;
      }
    }

    @media only screen and (max-width: $mobile-landscape) {
      [class*="grid-span"]:not([class*="-mobile"]) {
        grid-column: auto/span 12;
        grid-column-start: 1;
      }
      .grid-span-#{$i}-mobile {
        grid-column: auto/span $i;
      }
      .offset-#{$i}-mobile {
        grid-column: auto/span $i;
      }
      .grid-start-#{$i}-mobile {
        grid-column-start: $i;
      }

      .order-#{$i}-mobile {
        order: $i;
      }
    }
  }
}
