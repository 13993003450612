.block__masthead-article {
  &__row {
    .content_block {
      .main-heading {
        @media only screen and (min-width: $base) {
          font-size: rem(45);
          line-height: rem(45);
        }
        @media only screen and (min-width: $desktop-hidpi) {
          font-size: rem(55);
          line-height: rem(55);
        }
        @media only screen and (max-width: $mobile-landscape) {
          font-size: rem(28);
          line-height: rem(28);
        }
      }

      p:not(.category-heading) {
        margin-bottom: rem(15);
      }
      .published-text {
        color: #626262;
        @media only screen and (max-width: $mobile) {
          font-size: rem(16);
          line-height: rem(22);
        }
      }
      .short-description {
        line-height: rem(20);
      }
    }
  }
}

#postSection {
  background-color: $light-grey-bg;
  padding-block: 0px;
  margin-top: 0;

  @media only screen and (min-width: $phablet) {
    padding-block: rem(60);
    margin-top: rem(-30);
    opacity: 1;
  }

  @media only screen and (min-width: $phablet) {
    padding-block: rem(60);
    margin-top: rem(-30);
  }

  // @media only screen and (min-width: $tablet) {
  //   padding-block: rem(100);
  //   margin-top: rem(-60);
  // }

  @media only screen and (min-width: $desktop) {
    padding-block: rem(100);
    margin-top: rem(-60);
  }

  // @media only screen and (max-width: $base) {
  //   padding-block: rem(60);
  //   margin-top: rem(-30);
  //   opacity: 1;
  // }

  // @media only screen and (max-width: $phablet) {
  //   padding-block: rem(60);
  //   margin-top: 200px;
  //   opacity: 0.5;
  // }

  // @media only screen and (max-width: $mobile) {
  //   padding-block: rem(60);
  //   margin-top: 200px;
  //   opacity: 1;
  // }

  // @media only screen and (min-width: $tablet) {
  //   margin-top: rem(10);
  // }

  @media only screen and (min-width: $mobile-landscape + 1) {
    .post__content {
      .col-12 {
        grid-column: auto/span 10;
        grid-column-start: 2;
      }
      .block__text-block {
        .col-6 {
          grid-column: auto/span 5;
          grid-column-start: 2;

          &:nth-child(even) {
            grid-column-start: 7;
          }
        }
      }
      .block__recipe-text-block {
        .col-4 {
          grid-column: auto/span 4;
          grid-column-start: 2 !important;
        }
      }

      .block__text-block-1-2-column,
      .block__recipe-text-block {
        p,
        ul,
        ol {
          font-size: rem(16);
          line-height: rem(25);
        }
      }
    }
  }
}
