/**
 * COMMON CLASSES
 * This file contains a variety of shorthand classes for
 *    + Typography
 *    + Visibility
 *    + Accessibility/Screen reader
 */

@import "./base";

// Typography classes
.is-capitalized {
  text-transform: capitalize;
}
.is-lowercase {
  text-transform: lowercase;
}
.is-uppercase {
  text-transform: uppercase;
}
.is-italic {
  font-style: italic;
}
.is-underlined {
  text-decoration: underline;
}
.has-text-weight-light {
  font-weight: 300;
}
.has-text-weight-normal {
  font-weight: 400;
}
.has-text-weight-medium {
  font-weight: 500;
}
.has-text-weight-semibold {
  font-weight: 600;
}
.has-text-weight-bold {
  font-weight: 700;
}
.has-text-decoration-none {
  text-decoration: none;
}

.has-text-align-center {
  text-align: center;
}

// Visibility classes a
.is-hidden {
  display: none;
}
@media only screen and (max-width: $mobile-landscape) {
  .is-hidden-mobile {
    display: none !important;
  }
}
@media only screen and (min-width: $phablet) and (max-width: $tablet) {
  .is-hidden-tablet {
    display: none !important;
  }
}
@media only screen and (min-width: $base) {
  .is-hidden-desktop {
    display: none !important;
  }
}
.is-invisible {
  visibility: hidden;
}
@media only screen and (max-width: $mobile-landscape) {
  .is-invisible-mobile {
    visibility: hidden;
  }
}
@media only screen and (min-width: $phablet) and (max-width: $tablet) {
  .is-invisible-tablet {
    visibility: hidden;
  }
}
@media only screen and (min-width: $base) {
  .is-invisible-desktop {
    visibility: hidden;
  }
}

// Accessibility
.is-screen-reader-only {
  border: none;
  clip: rect(0, 0, 0, 0);
  height: 0.01em;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 0.01em;
}
