.site-ani__fade-in {
  opacity: 0;
  transition: opacity 1s;
  transition-delay: 800ms;
}

.site-ani__fade-in.state-ani-go {
  opacity: 1;
}

.site-ani__slide-up {
  transform: translateY(50px);
  opacity: 0;
  transition: opacity 1s, transform 1s;
  transition-delay: 800ms;
}

.site-ani__slide-up.state-ani-go {
  transform: none;
  opacity: 1;
}

.site-ani__slide-right {
  transform: translateX(-50px);
  opacity: 0;
  transition: opacity 1s, transform 1s;
  transition-delay: 800ms;
}

.site-ani__slide-right.state-ani-go {
  transform: none;
  opacity: 1;
}

.site-ani__slide-left {
  transform: translateX(50px);
  opacity: 0;
  transition: opacity 1s, transform 1s;
  transition-delay: 800ms;
}

.site-ani__slide-left.state-ani-go {
  transform: none;
  opacity: 1;
}

.site-ani__pop-in {
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 1s, transform 1s;
  transition-delay: 800ms;
}

.site-ani__pop-in.state-ani-go {
  opacity: 1;
  transform: none;
}

.site-ani__pop-blur-in {
  opacity: 0;
  transform: scale(0.8);
  filter: blur(5px);
  transition: opacity 1s, transform 1s, filter 1s;
  transition-delay: 800ms;
}

.site-ani__pop-blur-in.state-ani-go {
  opacity: 1;
  filter: none;
  transform: none;
}

.site-ani__land-in {
  opacity: 0;
  transform: scale(1.2);
  transition: opacity 1s, transform 1s;
  transition-delay: 800ms;
}

.site-ani__land-in.state-ani-go {
  opacity: 1;
  transform: none;
}

.site-ani__land-blur-in {
  opacity: 0;
  transform: scale(1.2);
  filter: blur(5px);
  transition: opacity 1s, transform 1s, filter 1s;
  transition-delay: 800ms;
}

.site-ani__land-blur-in.state-ani-go {
  opacity: 1;
  filter: none;
  transform: none;
}

.site-ani__blur-in {
  opacity: 0;
  filter: blur(10px);
  transition: opacity 2s, filter 2s;
  transition-delay: 800ms;
}

.site-ani__blur-in.state-ani-go {
  opacity: 1;
  filter: none;
}

.site-ani__blur-in-b {
  filter: blur(10px);
  transition: filter 1s;
  transition-delay: 800ms;
}

.site-ani__blur-in-b.state-ani-go {
  filter: none;
}

.site-ani__fade-in-b {
  opacity: 0;
  transition: opacity 2s;
  transition-delay: 800ms;
}

.site-ani__fade-in-b.state-ani-go {
  opacity: 1;
}

.site-ani__delay-1 {
  transition-delay: 800ms;
}
