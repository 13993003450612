.script-heading {
  font-family: $script-heading-font-family;
  color: $default-font-color;
  font-size: rem(48);
  line-height: rem(48);
  font-weight: 400;
  letter-spacing: rem(0.84);
  margin-bottom: rem(4);

  @include desktop-large {
    font-size: rem(42);
    line-height: rem(42);
  }

  @include desktop {
    font-size: rem(42);
    line-height: rem(42);
  }

  @include base {
    font-size: rem(42);
    line-height: rem(42);
  }

  @include tablet {
    font-size: rem(42);
    line-height: rem(42);
  }

  @include phablet {
    font-size: rem(30);
    line-height: rem(30);
  }

  @include mobile-landscape {
    font-size: rem(32);
    line-height: rem(32);
  }

  @include mobile {
    font-size: rem(30);
    line-height: rem(30);
  }
}

.main-heading {
  font-family: $main-heading-font-family;
  color: $default-font-color;
  font-size: rem(70);
  line-height: rem(70);
  font-weight: 400;

  @include desktop-hidpi {
    font-size: rem(90);
    line-height: rem(90);
  }

  @include desktop-large {
    font-size: rem(70);
    line-height: rem(70);
  }

  @include desktop {
    font-size: rem(55);
    line-height: rem(55);
  }

  @include base {
    font-size: rem(55);
    line-height: rem(55);
  }

  @include tablet {
    font-size: rem(32);
    line-height: rem(32);
  }

  @include phablet {
    font-size: rem(32);
    line-height: rem(32);
  }

  @include mobile-landscape {
    font-size: rem(38);
    line-height: rem(38);
  }

  @include mobile {
    font-size: rem(32);
    line-height: rem(32);
  }
}

.category-heading {
  font-size: rem(12);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: rem(1.44);
}

h1 {
  font-size: rem(70);
  font-family: $main-heading-font-family;
  font-weight: 400;
  line-height: rem(70);
  margin-bottom: rem(20);

  @include desktop-hidpi {
    font-size: rem(90);
    line-height: rem(90);
  }

  @include tablet {
    font-size: rem(38);
    line-height: rem(38);
  }

  @include phablet {
    font-size: rem(38);
    line-height: rem(38);
  }

  @include mobile-landscape {
    font-size: rem(45);
    line-height: rem(45);
  }

  @include mobile {
    font-size: rem(32);
    line-height: rem(32);
  }
}

h2,
h2.main-heading {
  font-size: rem(55);
  font-family: $main-heading-font-family;
  font-weight: 400;
  line-height: rem(55);
  margin-bottom: rem(20);

  @include desktop-hidpi {
    font-size: rem(65);
    line-height: rem(65);
  }

  @include tablet {
    font-size: rem(32);
    line-height: rem(32);
  }

  @include phablet {
    font-size: rem(32);
    line-height: rem(32);
  }

  @include mobile-landscape {
    font-size: rem(38);
    line-height: rem(38);
  }

  @include mobile {
    font-size: rem(32);
    line-height: rem(32);
  }
}

h3 {
  font-size: rem(45);
  font-family: $main-heading-font-family;
  font-weight: 400;
  line-height: rem(45);
  margin-bottom: rem(20);

  @include desktop-hidpi {
    font-size: rem(55);
    line-height: rem(55);
  }

  @include tablet {
    font-size: rem(36);
    line-height: rem(36);
  }

  @include phablet {
    font-size: rem(36);
    line-height: rem(36);
  }

  @include mobile-landscape {
    font-size: rem(36);
    line-height: rem(36);
  }

  @include mobile {
    font-size: rem(28);
    line-height: rem(28);
  }
}

h4 {
  font-size: rem(32);
  font-family: $main-heading-font-family;
  font-weight: 400;
  line-height: rem(32);
  margin-bottom: rem(5);

  @include desktop-hidpi {
    font-size: rem(40);
    line-height: rem(40);
  }

  @include tablet {
    font-size: rem(28);
    line-height: rem(28);
  }

  @include phablet {
    font-size: rem(18);
    line-height: rem(18);
  }

  @include mobile-landscape {
    font-size: rem(20);
    line-height: rem(20);
  }

  @include mobile {
    font-size: rem(20);
    line-height: rem(20);
  }
}

h5 {
  font-size: rem(28);
  font-family: $main-heading-font-family;
  font-weight: 400;
  line-height: rem(28);
  margin-bottom: rem(15);

  @include desktop-hidpi {
    font-size: rem(40);
    line-height: rem(40);
  }

  @include tablet {
    font-size: rem(28);
    line-height: rem(28);
  }

  @include phablet {
    font-size: rem(18);
    line-height: rem(18);
  }

  @include mobile-landscape {
    font-size: rem(20);
    line-height: rem(20);
  }

  @include mobile {
    font-size: rem(18);
    line-height: rem(18);
  }
}

h6 {
  font-size: rem(24);
  font-family: $main-heading-font-family;
  font-weight: 400;
  line-height: rem(24);
  margin-bottom: rem(15);

  @include desktop-hidpi {
    font-size: rem(36);
    line-height: rem(36);
  }

  @include tablet {
    font-size: rem(20);
    line-height: rem(20);
  }

  @include phablet {
    font-size: rem(20);
    line-height: rem(20);
  }

  @include mobile-landscape {
    font-size: rem(24);
    line-height: rem(24);
  }

  @include mobile {
    font-size: rem(20);
    line-height: rem(20);
  }
}
