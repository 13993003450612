.age-gate {
  max-width: 600px !important;
}

.age-gate__heading-title--logo {
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
}

.age-gate__headline {
  font-size: 45px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 30px;
  margin-top: 30px !important;
}

.age-gate__subheadline {
  font-family: Avenir Next;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 140%;
}

.age-gate-country-selector {
  max-width: 380px !important;
  width: 100%;
}

#country_selector {
  width: 100% !important;
  margin-bottom: 20px;
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
  margin-top: 20px;
}

.dob_title {
  max-width: 380px;
  width: 100%;
  text-align: left;
  margin: 0 auto;
}

.dob_title p {
  font-family: Avenir Next;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

ol.age-gate__form-elements {
  max-width: 380px;
  margin: 0 auto;
}

.age-gate__select {
  border: none !important;
  background: #E8E8E8 !important;
  border-radius: 1px !important;
  max-width: 120px;
}

.age-gate__button {
  border-radius: 2px !important;
  background: #8F1920 !important;
  min-width: 142px;
  padding: 16px 29px 16px 29px !important;
  font-family: 'Avenir Next';
  font-size: 12px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 100%;
  letter-spacing: 1.44px;
  margin-top: 4px;
  margin-bottom: 16px;
}

.age-gate__error {
  margin-bottom: 10px;
  font-weight: 400 !important;
  font-size: 14px;
}

@media only screen and (max-width: 600px) {
  .age-gate {
    padding: 0px 25px;
  }

  .age-gate__headline {
    line-height: 50px !important;
  }

  .age-gate__subheadline {
    padding: 0px 50px;
  }
}

#age-verification-modal {
  background-size: cover;
  background-repeat: no-repeat;
}

#background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.age-gate-logo {
  width: 147.857px;
  height: 75px;
  background-image: url("../resources/images/villa-maria-logo-2.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.bottom-bar {
  animation: slideInFromBottom 0.5s ease-out;

  .title-font {
    font-family: Bigola Display;
  }

  .description-font {
    font-family: Avenir Next;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.age-gate-error {
  color: #8F1920 !important;
  font-size: 14px;
  font-weight: normal;
}
