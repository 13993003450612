/**
 * RESET CSS
 * Simple custom reset CSS
 */

@import "./base";

:root {
  --base-font-size: #{$base-font-size};
  --base-font-family: #{$base-font-family};
  --container-max-width: #{$container-max-width};
  --gap: #{$gap};
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  font-variant-ligatures: none;
  outline: none;
  box-sizing: border-box;
}

html {
  font-size: var(--base-font-size);
  line-height: 1.6;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;

  @media only screen and (min-width: $phablet) and (max-width: $desktop) {
    font-size: 14px;
    line-height: 1.4;
  }

  @media only screen and (max-width: 360px) {
    font-size: 15px;
  }
}

body[class*="theme-front"] {
  font-family: $base-font-family;
  font-weight: 400;
  color: $default-font-color;
}

a {
  color: inherit;
  text-decoration: inherit;
}

button,
input,
select,
textarea {
  appearance: none;
  font-size: $base-font-size;
  border: 0;
  border-radius: 0;
  background: transparent;
}

button {
  cursor: pointer;
}

blockquote {
  border-left: inherit;
  font-size: inherit;
  line-height: inherit;
}

figcaption {
  text-align: inherit;
}

ol,
ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

section,
article {
  width: 100%;
}

main > div {
  width: 100%;
}

img {
  max-width: 100%;
  display: block;
}
