@import "./base";
@import "_animations.scss";

.carousel_info{
  max-width: 280px;
  margin: 0 auto;

  p {
    font-family: $base-font-family;
  }
  h5 {
    font-family: $main-heading-font-family;
  }
}

.splide {

  &__slide {

    &.img-transition{
      div:first-child
      {
        img{
          transition: .3s all linear

        }
      }
      &:hover{
        div:first-child img{
          transform: scale(1.05);
        }
      }
    }

    &.title-animation:hover{
      .carousel_info{
        a {
          h5{
            color: $link-color;
          }
        }
      }
    }

    &:hover a h4,
    &:hover a h5 {
      color: $link-color;
    }
  }

  &__arrows {
    padding-top: rem(30);

    .splide__arrow {
      text-indent: -9999px;
      background-image: url("../resources/icons/arrow-long-hover.svg");
      background-position: center right;
      width: rem(85);
      height: rem(46);
      margin-right: rem(20);
      transition: all 0.5s;
      &:disabled {
        background-image: url("../resources/icons/arrow-long-inactive.svg");
      }

      &:hover:not(:disabled) {
        width: rem(105);
        margin: 0;
        transition: all 0.5s;
      }
      &--prev {
        transform: scaleX(-1);
        margin-left: rem(20);
        margin-right: rem(20);

        &:hover:not(:disabled) {
          transition: all 0.5s;
          width: rem(105);
          margin-left: 0;
          margin-right: rem(20);
        }
      }

      @media only screen and (max-width: $mobile) {
        width: rem(70);
        height: rem(15);

        &:hover:not(:disabled) {
          width: rem(85);
        }

        &--prev {
          &:hover:not(:disabled) {
            width: rem(85);
          }
        }
      }
    }
  }
}