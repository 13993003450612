header {
  &.header {
    width: 100%;
    display: flex;
    padding-block: rem(15);
    justify-content: space-between;
    position: absolute;
    z-index: 9999;
    transition: top 0.3s;
    top: 0px;

    &.open {
      background: $white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
      .hamburger {
        background-color: #212121;
      }
    }

    &.sticky {
      top: 0;
      position: fixed;
      background-color: $white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    }

    .header__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include content-contain();
    }

    .header__logo {
      position: relative;
      width: 100%;
      max-width: 158px;
      height: 33px;
      z-index: 999;

      img {
        position: absolute;
        left: 0;
        top: 0;
        transition: opacity 0.5s;
      }
    }

    #header-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 0;
      background-color: #fff;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.14) !important;
      transition: height 300ms;
    }

    .header {
      &__nav_search {
        display: flex;
      }

      &__navigation {
        ul {
          list-style: none;
          display: flex;
          gap: 0px;
          @media screen and (min-width: $desktop) {
            gap: 40px;
          }
          li {
            font-size: rem(12);
            line-height: rem(40);
            letter-spacing: rem(1.08);
            text-transform: uppercase;
            font-weight: 600;

            &:last-child {
              margin-right: rem(20);
            }
          }
        }

        &__items {
          padding: 1rem;
          width: 100%;
          display: flex;
        }
      }

      &__search {
        @media only screen and (max-width: $desktop - 1) {
          display: none;
        }
        width: 39px;
        height: 39px;
        position: relative;

        .search-icon {
          width: 39px;
          height: 39px;
          border-radius: 50%;
          padding: rem(12);
          background: url('../resources/icons/search.svg') #ededed no-repeat
            center;
          display: block;
          transition: width 200ms;
        }

        &.open {
          width: 245px;
          padding-right: 32px;
          transition: width 200ms;
        }
      }
    }
  }

  &.header {
    .header__logo {
      .light {
        opacity: 1;
      }
      .dark {
        opacity: 0;
      }

      &.light {
        img {
          transition: opacity 0s;
        }
        .light {
          opacity: 0;
        }
        .dark {
          opacity: 1;
        }
      }
    }
  }

  &.header {
    &.sticky {
      .theme-dark & {
        background-color: #1c1c1e;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
      }
    }
  }

  &.header {
    .header__logo {
      .theme-dark & {
        .light {
          opacity: 0;
        }
        .dark {
          opacity: 1;
        }
      }
    }

    a {
      .theme-dark & {
        color: white;
      }
    }
  }
}

body.home .header:not(.sticky):not(.open) .header__navigation ul li a {
  color: #fff; // JY: don't think this is working?
}

.searchform,
.searchform2 {
  .search-form {
    display: flex;
    align-items: center;
  }

  input[type='text'] {
    border: 0;
    width: 214px;
  }
}

.searchform {
  display: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  &.open {
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }
}

.search-bar {
  padding-top: rem(30);
  border-top: 1px solid #a4a4a5;
  @media only screen and (min-width: $desktop) {
    display: none;
  }
}

.searchform2 {
  width: 213px;
}

.submit-wrapper {
  height: 36px;
  width: 36px;
  position: absolute;
  right: 0px;
  top: 2px;
  cursor: pointer;

  input[type='submit'] {
    width: 100%;
    height: 100%;
    background: url('../resources/icons/search.svg') no-repeat center;
    text-indent: -9999px;
    cursor: pointer;

    &:hover,
    &:hover {
      animation: bounce 0.3s linear;
    }
  }
}

.close-icon {
  display: none;
  width: 40px;
  height: 40px;
  background: url('../resources/icons/close.svg') no-repeat center;
  position: absolute;
  right: -12px;
  top: 0px;
}

.mobile-menu-toggle {
  display: none;
  width: 25px;
  height: 25px;
}

.hamburger {
  width: 25px;
  height: 2px;
  background-color: #000;
  margin: 7px 0 0;
  transition: 0.4s;

  &:nth-child(1) {
    margin: 0;
  }
  &:nth-child(3) {
    width: 15px;
    margin-left: 10px;
  }
}

.home {
  .hamburger {
    background-color: #fff;
  }
}

header.sticky .hamburger {
  background-color: $dark-grey-bg;
  .theme-dark & {
    background-color: $white;
  }
}

.mobile-menu-open .hamburger {
  margin: 0;
}

.mobile-menu-open .hamburger:nth-child(1) {
  transform: rotate(-45deg) translate(-8px, 9px);
  background-color: $dark-grey-bg;
  width: 25px;

  .theme-dark & {
    background-color: $white;
  }
}

.mobile-menu-open .hamburger:nth-child(2) {
  opacity: 0;
  display: none;
}

.mobile-menu-open .hamburger:nth-child(3) {
  transform: rotate(45deg) translate(7px, 7px);
  background-color: $dark-grey-bg;
  width: 25px;

  .theme-dark & {
    background-color: $white;
  }
}

@media only screen and (max-width: $desktop - 1) {
  header.header {
    .header__navigation {
      // display: none;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      // padding: 0;
      padding: 0 $desktop-padding 0;
      overflow: hidden;
      max-height: 0;
      transition: all 0.5s;

      .theme-dark & {
        background-color: #1c1c1e;
      }

      @media only screen and (max-width: $tablet - 1) {
        padding: 0 $tablet-padding 0;
      }

      @media only screen and (max-width: $mobile - 1) {
        padding: 0 $mobile-padding 0;
      }

      ul {
        flex-direction: column;

        li {
          line-height: rem(12);
          margin-bottom: rem(30);
        }
      }

      .header__nav_openmenu {
        transform: translateY(200px);
        transition: transform 0.5s;
      }

      &.open {
        // display: flex;
        padding: 100px $desktop-padding 72px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
        max-height: 100vh;
        top: 0;

        .header__nav_openmenu {
          transform: translateY(0);

          .theme-dark & {
            color: $white;
          }
        }

        @media only screen and (max-width: $tablet - 1) {
          padding: 100px $tablet-padding 72px;
        }

        @media only screen and (max-width: $mobile - 1) {
          padding: 100px $mobile-padding 72px;
        }
      }
    }
  }

  .mobile-menu-toggle {
    display: block;
    cursor: pointer;
    z-index: 99999;
  }
}

@media only screen and (max-width: $tablet - 1) {
  header.header {
    .header__navigation {
      &.open {
        padding-left: $tablet-padding;
      }
    }
  }
}

@media only screen and (max-width: $mobile - 1) {
  header.header {
    .header__navigation {
      &.open {
        padding-left: $mobile-padding;
      }
    }
  }
}

// Search icon bounce on hover
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(4px);
  }
  100% {
    transform: translateY(0);
  }
}
