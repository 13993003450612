.tab-container {
  display: flex;
  justify-content: center;
}

.tab {
  margin-right: 16px;
  padding-bottom: 4px;
  cursor: pointer;
  color: var(--Black, #222221);
  font-family: "Avenir Next";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1.08px;
  text-transform: uppercase;
}

.tab-content {
  display: none;
}

.active-tab {
  font-weight: 700;
  border-bottom: 1px solid #000;
}

.active-content {
  display: block;
}
