#single-product-page {
  &.mode-light {
    .product-detail {
      .btn-download-light {
        display: block;
      }

      .btn-download-dark {
        display: none;
      }
    }
  }

  .breadcrumbs {
    li {
      * {
        color: #626262;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  &.mode-dark {
    background: #1c1c1e !important;

    nav {
      ol {
        li {
          * {
            color: #a4a4a5;
          }
        }
      }
    }

    .product-detail {
      color: #ffffff !important;

      .tom-category {
        color: #a4a4a5;
      }

      .tom-title,
      .tom-vintage {
        color: #ffffff !important;
      }

      .btn-download-light {
        display: none;
      }

      .btn-download-dark {
        display: block;
      }

      .tom-primary-button {
        background: #ad9b5c !important;

        a {
          color: #222221 !important;
        }
      }

      .tom-secondary-button {
        a > div > div {
          color: #ad9b5c !important;
        }
      }

      .tab-container {
        div {
          color: #ffffff !important;

          &.active-tab {
            border-color: #ffffff !important;
          }
        }
      }

      #tab_description,
      #tab_vintage,
      #tab_vineyards {
        color: #ffffff !important;
      }
    }

    @media (max-width: 767px) {
      div {
        &.wine_product {
          background-size: 110% !important;
          background-position: right -50px !important;
        }
      }
    }
  }
}
