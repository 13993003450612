/*** BASE VARIABLES ***/

@use "sass:math";

@import "./fonts";

// REM base
$rem-base: 14;

// Function to convert pixel values to REM units based on a defined base
@function rem($pxValue) {
  @return math.div($pxValue, $rem-base) * 1rem;
}

// Typography
$base-font-size: 14px;
$base-font-family: "Avenir Next";
$base-line-height: 20px;
$script-heading-font-family: "White Oleander Upright";
$main-heading-font-family: "Bigola Display";
$gap: 1.5625rem;
$gutter: 0.78125rem;
$container-max-width: 1440px;
$container-margin: $gap;

// Colors
$white: #fff;
$black: #000;
$light-grey-bg: #f5f5f5;
$dark-grey-bg: #212121;
$default-font-color: #222221;
$gold: #ad9b5c;
$link-color: #8f1920;
$link-color-hover: #6d1515;
$btn-bg-color: #8f1920;
$btn-bg-hover: linear-gradient(86deg, #6d1515 0%, #8f1920 100%);

// Breakpoints
$desktop-hidpi: 1440px;
$desktop-large: 1440px;
$desktop: 1280px;
$base: 992px;
$tablet: 991px;
$phablet: 768px;
$mobile-landscape: 767px;
$mobile: 478px;

// Breakpoints Padding
$desktop-hidpi-padding: 20rem;
$desktop-large-padding: 8rem;
$desktop-padding: 7rem;
$base-padding: 5.5rem;
$tablet-padding: 3.5rem;
$phablet-padding: 3.5rem;
$mobile-landscape-padding: 2.6rem;
$mobile-padding: rem(24);
