/**
 * FUNCTIONS AND MIXINS
 *
 * In this file you have
 *   - function to calculates the REM value for a pixel value using the defined $rem-base
 *   - Mixins to create utility classes for padding, margin, display, flex-direction, align-items, align-content and justify-content properties
 *   - Mixins to create @media queries for desktop-hidpi, desktop-large, desktop, base, tablet, phablet, mobile-landscape and mobile
 */

// Use Sass math
@use "sass:math";

// Import base variables
@import "./base";

/**** Grid Mixin ****/
// Sets up the basic properties for a CSS grid layout
@mixin grid() {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: $gap;
  align-content: space-between;
  width: 100%;
}

/**** Display Mixins ****/

// Generate utility classes for various display properties
$displays: "block" "grid" "flex" "inline" "inline-block" "inline-flex";
@each $display in $displays {
  .display-#{$display} {
    display: #{$display};
  }
  @media only screen and (min-width: $base) {
    .display-#{$display}-desktop {
      display: #{$display};
    }
  }
  @media only screen and (min-width: $phablet) and (max-width: $tablet) {
    .display-#{$display}-tablet {
      display: #{$display};
    }
  }
  @media only screen and (max-width: $mobile-landscape) {
    .display-#{$display}-mobile {
      display: #{$display};
    }
  }
}
// Generate utility classes for various flex-direction properties
$flex-directions: "row" "row-reverse" "column" "column-reverse";
@each $direction in $flex-directions {
  .flex-direction-#{$direction} {
    flex-direction: #{$direction} !important;
  }
  @media only screen and (max-width: $mobile-landscape) {
    .flex-direction-#{$direction}-mobile {
      flex-direction: #{$direction} !important;
    }
  }
}

// Generate utility classes for various background color properties
@mixin bg-color-mixin($name, $value) {
  .bg-color-#{$name} {
    background-color: $value;
  }
}
@include bg-color-mixin("light-grey", #f5f5f5);
@include bg-color-mixin("white", #ffffff);
@include bg-color-mixin("dark-grey", #212121);

// Generate utility classes for various background color properties
@mixin asset-order-mixin($name, $value) {
  .asset-order-#{$name} {
    order: $value;
  }
}
@include asset-order-mixin("left", 1);
@include asset-order-mixin("middle", 2);
@include asset-order-mixin("right", 3);

// Generate utility classes for align-items properties
@mixin align-items-mixin($name, $value) {
  .items-#{$name} {
    align-items: $value;
  }
}
@include align-items-mixin(start, flex-start);
@include align-items-mixin(end, flex-end);
@include align-items-mixin(center, center);
@include align-items-mixin(baseline, baseline);
@include align-items-mixin(stretch, stretch);

// Generate utility classes for align-content properties
@mixin align-content-mixin($name, $value) {
  .content-#{$name} {
    align-content: $value;
  }
}
@include align-content-mixin(center, center);
@include align-content-mixin(start, flex-start);
@include align-content-mixin(end, flex-end);
@include align-content-mixin(between, space-between);
@include align-content-mixin(around, space-around);
@include align-content-mixin(evenly, space-evenly);
// Generate utility classes for align-content properties
@mixin justify-content-mixin($name, $value) {
  .justify-#{$name} {
    justify-content: $value;
  }
}
@include justify-content-mixin(start, flex-start);
@include justify-content-mixin(end, flex-end);
@include justify-content-mixin(center, center);
@include justify-content-mixin(between, space-between);
@include justify-content-mixin(around, space-around);
@include justify-content-mixin(evenly, space-evenly);

/**** Margin and Padding Mixins ****/

// Generate utility classes for margin and padding properties
$properties: "padding" "padding-top" "padding-right" "padding-bottom"
  "padding-left" "margin" "margin-top" "margin-right" "margin-bottom"
  "margin-left";
$spacing-values: 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75,
  80, 85, 90, 95, 100, 120, 150, 175, 200;
// Mixin to generate spacing utility classes for each of the defined properties
@mixin generate-spacing($screen-suffix: "") {
  @each $property in $properties {
    @each $spacing-value in $spacing-values {
      $value: rem($spacing-value);
      .#{$property}-#{$spacing-value}#{$screen-suffix} {
        #{$property}: $value !important;
      }
    }
  }
}
// Create the default spacing options
@include generate-spacing;
// Create the spacing options for large screens
@media only screen and (min-width: $base) {
  @include generate-spacing("-desktop");
}
// Create the spacing options between phablet and tablet
@media only screen and (min-width: $phablet) and (max-width: $tablet) {
  @include generate-spacing("-tablet");
}
// Create the spacing options for mobile
@media only screen and (max-width: $mobile-landscape) {
  @include generate-spacing("-mobile");
}

/**** Media Query Mixins ****/

// Desktop HiDPI
@mixin desktop-hidpi {
  @media screen and (min-width: $desktop-hidpi + 1) {
    @content;
  }
}
// Desktop Large
@mixin desktop-large {
  @media screen and (min-width: $desktop-large + 1) and (max-width: $desktop-hidpi) {
    @content;
  }
}
// Desktop
@mixin desktop {
  @media screen and (min-width: $desktop + 1) and (max-width: $desktop-large) {
    @content;
  }
}
// Base (between Desktop and Tablet)
@mixin base {
  @media screen and (min-width: $tablet + 1) and (max-width: $desktop) {
    @content;
  }
}
// Tablet
@mixin tablet {
  @media screen and (min-width: $phablet + 1) and (max-width: $tablet) {
    @content;
  }
}
// Phablet (between Tablet and Mobile Landscape)
@mixin phablet {
  @media screen and (min-width: $mobile-landscape + 1) and (max-width: $phablet) {
    @content;
  }
}
// Mobile Landscape
@mixin mobile-landscape {
  @media screen and (min-width: $mobile + 1) and (max-width: $mobile-landscape) {
    @content;
  }
}
// Mobile
@mixin mobile {
  @media screen and (max-width: $mobile) {
    @content;
  }
}

// Generate utility classes for Illustration position properties
@mixin bg-mixin($name, $top, $right, $bottom, $left, $rotate, $scale) {
  .bg-#{$name} {
    position: relative;

    &::after {
      top: $top;
      right: $right;
      bottom: $bottom;
      left: $left;
      transform: scale($scale, -1) rotate($rotate);
    }
  }
}
@include bg-mixin(top-left, -6%, auto, auto, 0, 180deg, 1);
@include bg-mixin(top-right, -6%, 0, auto, auto, 180deg, -1);
@include bg-mixin(bottom-left, auto, auto, -6%, 0, 180deg, 1);
@include bg-mixin(bottom-right, auto, 0, -6%, auto, 180deg, -1);

.bg-left-center::after {
  right: auto;
  bottom: auto;
  left: 0;
  transform: scale(1, -1) rotate(180deg);
  top: calc(50% - 10rem);
}

.bg-right-center::after {
  right: 0;
  bottom: auto;
  left: auto;
  transform: scale(1, -1) rotate(180deg);
  top: calc(50% - 10rem);
}

@for $i from 1 through 100 {
  @include bg-mixin(
    "bottom-right-#{$i}",
    auto,
    0,
    #{$i} + "%",
    auto,
    180deg,
    -1
  );
}

$bg-imgs: "none" "citrus" "citrus-bundle" "foliage1" "foliage8" "foliage9"
  "foliage10" "foliage11" "foliage15" "foliage16" "olive1" "olive2" "olive3"
  "olive4" "strawberry1" "strawberry2";
@each $bg-img in $bg-imgs {
  .bg-img-#{$bg-img} {
    position: relative;
    &::after {
      $width: rem(288);
      $height: rem(293);
      position: absolute;
      // background-image: url("../resources/images/#{$bg-img}-leaf.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: $width;
      height: $height;
      content: "";
      z-index: 1;

      @include desktop() {
        width: $width * 0.8;
        height: $height * 0.8;
      }

      @include base() {
        width: $width * 0.7;
        height: $height * 0.7;
      }

      @include tablet() {
        width: $width * 0.6;
        height: $height * 0.6;
      }

      @include mobile-landscape() {
        width: $width * 0.5;
        height: $height * 0.5;
      }

      @include mobile() {
        width: $width * 0.5;
        height: $height * 0.5;
      }
    }
  }

  .bg-img-left-#{$bg-img} {
    position: relative;
    &::before {
      $width: rem(355);
      $height: rem(304);
      position: absolute;
      // background-image: url("../resources/images/#{$bg-img}-leaf.png");
      transform: scale(1, -1) rotate(180deg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: $width;
      height: $height;
      left: 0;
      top: -6%;
      content: "";
      z-index: 1;

      @include desktop() {
        width: $width * 0.8;
        height: $height * 0.8;
      }

      @include base() {
        width: $width * 0.7;
        height: $height * 0.7;
      }

      @include tablet() {
        width: $width * 0.6;
        height: $height * 0.6;
      }

      @include mobile-landscape() {
        width: $width * 0.5;
        height: $height * 0.5;
      }

      @include mobile() {
        width: $width * 0.5;
        height: $height * 0.5;
      }
    }
  }

  .bg-img-right-#{$bg-img} {
    position: relative;
    &::after {
      $width: rem(355);
      $height: rem(304);
      position: absolute;
      // background-image: url("../resources/images/#{$bg-img}-leaf.png");
      transform: scale(-1, -1) rotate(180deg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: $width;
      height: $height;
      right: 0;
      bottom: -6%;
      content: "";
      z-index: 1;

      @include mobile-landscape() {
        width: $width * 0.5;
        height: $height * 0.5;
      }

      @include mobile() {
        width: $width * 0.5;
        height: $height * 0.5;
      }
    }
  }
}

$bg-full-imgs: "citrus-leaf" "citrus-flower1" "citrus-flower2" "citrus-flower3"
  "olive-leaf1" "olive-leaf2" "olive-leaf3" "olive-large-leaf"
  "strawberry-leaves";
@each $bg-full-img in $bg-full-imgs {
  .bg-img-#{$bg-full-img}-full {
    position: relative;
    &::after {
      $width: rem(280);
      $height: rem(240);
      position: absolute;
      // background-image: url("../resources/images/#{$bg-full-img}-full.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: $width;
      height: $height;
      content: "";

      @media only screen and (max-width: $mobile-landscape) {
        $width: $width * 0.8;
        $height: $height * 0.8;
      }
    }
  }
}
