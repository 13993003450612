@import "./base";

p,
[class*="block__text"] ul li,
[class*="block__text"] ol li {
  font-weight: $base-font-size;
  line-height: $base-line-height;
  @include desktop-hidpi() {
    font-size: rem(18);
    line-height: rem(25);
  }
}

.grid-container {
  @include grid();
}

.site-padding {
  padding: 120px 0;

  @media only screen and (max-width: $mobile-landscape) {
    padding: 58px 0px;
    &__desktop {
      padding: 0;
    }
  }
}

.main {
  min-height: 60vh;
}

@mixin content-contain {
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  padding-inline: 80px;

  @include desktop-hidpi() {
    max-width: $desktop-hidpi;
  }

  @include desktop-large() {
    max-width: $desktop-large;
    padding-inline: $desktop-large-padding;
  }

  @include desktop() {
    max-width: $desktop-large;
    padding-inline: $desktop-padding;
  }

  @include base() {
    max-width: $desktop;
    padding-inline: $base-padding;
  }

  @include phablet() {
    max-width: $tablet;
    padding-inline: $tablet-padding;
  }

  @include mobile-landscape() {
    max-width: $mobile-landscape;
    padding-inline: $tablet-padding;
  }

  @include mobile() {
    max-width: $mobile;
    padding-inline: $mobile-padding;
  }
}

.site-align,
.foreground-content {
  @include content-contain();
}

.site-align-desktop-off {
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;

  @include desktop-hidpi() {
    max-width: $desktop-hidpi;
  }

  @include desktop-large() {
    max-width: $desktop-large;
    padding-inline: $desktop-large-padding;
  }

  @include desktop() {
    max-width: $desktop-large;
    padding-inline: $desktop-padding;
  }

  @include base() {
    max-width: $desktop;
    padding-inline: $base-padding;
  }

  @include tablet() {
    max-width: $base;
    padding-inline: $tablet-padding;
  }

  @include phablet() {
    max-width: $tablet;
    padding-inline: $tablet-padding;
  }
}

.site-align-mobile-off {
  margin: 0 auto;
  width: 100%;

  @include mobile-landscape() {
    max-width: $mobile-landscape;
    padding-inline: $tablet-padding;
  }

  @include mobile() {
    max-width: $mobile;
    padding-inline: $mobile-padding;
  }
}

.site-link {
  font-size: rem(12);
  letter-spacing: 1.44px;
  font-weight: 600;
  text-transform: uppercase;
  color: $link-color;
  position: relative;
  padding-right: 25px;
  display: inline-block;
  position: relative;
  line-height: rem(30);

  &:hover {
    color: $link-color-hover;
  }

  &::after {
    position: absolute;
    right: 10px;
    top: 50%;
    width: 6px;
    height: 12px;
    content: "";
    background-image: url("../resources/icons/arrow-icon.svg");
    transform: translate(0, -50%);
    transition: transform 0.5s;
  }

  &:hover::after {
    background-image: url("../resources/icons/arrow-icon-hover.svg");
    transform: translate(5px, -50%);
    transition: transform 0.5s;
  }
}

.site-button,
.nav-button > a {
  font-size: 0.9rem;
  letter-spacing: 1.44px;
  font-weight: 600;
  text-transform: uppercase;
  color: $link-color;
  padding: 0 29px;
  height: 44px;
  line-height: 44px;
  background: $btn-bg-color;
  color: $white;
  display: inline-block;
  border-radius: 2px;

  &:hover,
  &:focus {
    background: $btn-bg-hover;
    color: #fff;
  }
  &_secondary {
    background: transparent;
    color: $link-color;
  }
}

.iframe-full iframe {
  width: 100% !important;

  .button-main {
    display: inline-flex;
    padding: 16px 29px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 2px;
    border: 2px solid var(--Red-from-Logo, #8f1920);

    span {
      color: var(--Red-from-Logo, #8f1920);
      text-align: center;
      font-family: Avenir Next;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 12px */
      letter-spacing: 1.44px;
      text-transform: uppercase;
    }

    &:hover {
      background: linear-gradient(86.36deg, #6d1515 21.31%, #8f1920 103.8%);

      span {
        color: #ffffff;
      }
    }
  }
}

// Dark mode CSS

.theme-dark {
  .site-link {
    color: #ad9b5c;

    &::after {
      background-image: url("../resources/icons/arrow-icon-gold.png");
      background-size: 100%;
      background-repeat: no-repeat;
    }

    &:hover::after {
      background-image: url("../resources/icons/arrow-icon-gold.png");
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }
}
