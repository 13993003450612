/*** PAGE specific styles ***/

body.page {
  // Define the styles to apply to blocks in a page that has WYSIWYG content defined
  .content-wysiwyg {
    h1 {
      font-family: $main-heading-font-family;
      font-size: rem(70);
      line-height: rem(68.6);
      font-weight: 400;
      color: $black;
    }
    h2 {
      margin-bottom: rem(30);
      font-family: $main-heading-font-family;
      font-size: rem(58);
      line-height: rem(57.5);
      font-weight: 400;
      color: $black;
    }
    h3 {
      margin-bottom: rem(30);
      font-family: $main-heading-font-family;
      font-size: rem(45);
      line-height: rem(44.1);
      font-weight: 400;
      color: $black;
    }
    h4 {
      margin-bottom: rem(24);
      font-family: $main-heading-font-family;
      font-size: rem(34);
      line-height: rem(34);
      font-weight: 400;
    }
    h5 {
      margin-bottom: rem(20);
      font-family: $main-heading-font-family;
      font-size: rem(28);
      line-height: rem(28);
      font-weight: 400;
    }
    h6 {
      margin-bottom: rem(20);
      font-family: $main-heading-font-family;
      font-size: rem(22);
      line-height: rem(22);
      font-weight: 400;
    }
    p {
      margin-bottom: $base-font-size;
      font-family: $base-font-family;
      font-size: $base-font-size;
      font-weight: 400;
      line-height: $base-line-height;
      color: #000;
    }
    ul {
      list-style: disc;
      li {
        margin-bottom: rem(10);
        font-family: $base-font-family;
        font-size: $base-font-size;
        font-weight: 400;
        line-height: $base-line-height;
      }
    }

    ol {
      list-style: decimal;
      li {
        margin-bottom: rem(10);
        font-family: $base-font-family;
        font-size: $base-font-size;
        font-weight: 400;
        line-height: $base-line-height;
      }
    }

    blockquote {
      // TODO: Do we need add a blockquote
    }
}
}