@font-face {
  font-family: "Avenir Next";
  src: url("../resources/fonts/avenirnext-regular-webfont.woff2")
      format("woff2"),
    url("../resources/fonts/avenirnext-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir Next";
  src: url("../resources/fonts/avenirnext-medium-webfont.woff2") format("woff2"),
    url("../resources/fonts/avenirnext-medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir Next";
  src: url("../resources/fonts/avenirnext-demibold-webfont.woff2")
      format("woff2"),
    url("../resources/fonts/avenirnext-demibold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir Next";
  src: url("../resources/fonts/avenirnext-bold-webfont.woff2") format("woff2"),
    url("../resources/fonts/avenirnext-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir Next";
  src: url("../resources/fonts/avenirnext-heavy-webfont.woff2") format("woff2"),
    url("../resources/fonts/avenirnext-heavy-webfont.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bigola Display";
  src: url("../resources/fonts/bigoladisplay-regular-webfont.woff2")
      format("woff2"),
    url("../resources/fonts/bigoladisplay-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "White Oleander Upright";
  src: url("../resources/fonts/whiteoleanderupright-webfont.woff2")
      format("woff2"),
    url("../resources/fonts/whiteoleanderupright-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
